<template>
    <zw-sidebar @shown="shown" :title="$t('common.title.customer_report')">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <validation-observer tag="div" ref="observer">
            <div v-if="!loading">

                <b-row v-if="form.customFields">
                    <b-col v-for="field in form.customFields" :key="field.name" sm="3">
                        <template v-if="field.name == 'status'">
                            <zw-select-group v-model="form.customValues[field.name]"
                                             :options="getStatuses()"
                                             :name="'custom.'+field.model+'.'+field.name"
                                             :label="field.options.label ? field.options.label : field.name"
                                             text-field="name"
                                             value-field="id"
                            ></zw-select-group>
                        </template>
                        <template v-else-if="field.name == 'competent_person'">
                            <zw-select-group v-model="form.customValues[field.name]"
                                             :options="getUsersList()"
                                             :name="'custom.'+field.model+'.'+field.name"
                                             :label="field.options.label ? field.options.label : field.name"
                                             text-field="fullname"
                                             value-field="id"
                            ></zw-select-group>
                        </template>
                        <custom-field v-else v-model="form.customValues[field.name]" :field="field"></custom-field>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12">
                        <b-form-checkbox
                            v-model="form.notification_enabled"
                            switch
                            :value="1"
                            :unchecked-value="0"
                        >{{ $t('common.label.notification.enabled') }}
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="6" v-if="form.notification_enabled">
                        <zw-date-group v-model="form.notification_date"
                                       name="notification.date"
                                       validate="required"
                        ></zw-date-group>
                    </b-col>
                    <b-col cols="12" v-if="form.notification_enabled">
                        <zw-textarea-group v-model="form.notification_text"
                                           name="notification.text"
                                           :placeholder="' '"
                        ></zw-textarea-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" class="text-xs-center">
                        <b-row align-h="end">
                            <b-col sm="3" v-if="payload.id" class="text-sm-right">
                                <b-button block @click="deleteReport(payload.id)" variant="danger">
                                    {{ $t('common.button.delete') }}
                                </b-button>
                            </b-col>
                            <b-col sm="3" class="text-sm-right">
                                <b-button block @click="onSubmit()" variant="primary">
                                    {{ $t('common.button.save') }}
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>

            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {
                notification_enabled: false,
                notification_date: null,
                notification_text: null,
            },
            form: null
        }
    },
    methods: {
        ...mapGetters('CustomerReports', ['getReport']),
        ...mapGetters('Users', ['getUsersList']),
        ...mapGetters('Status', ['getStatuses']),
        shown() {
            const usersList = this.$store.dispatch('Users/fetchUsersList')
            const statusesFetch = this.$store.dispatch('Status/fetchStatuses')
            const report = this.$store.dispatch('CustomerReports/fetchReport', {
                id: this.payload.id,
                customerId: this.payload.customerId
            })

            Promise.all([report, usersList, statusesFetch])
                .then(() => {
                    this.form = {...this.defaultForm, ...this.getReport()}
                    this.form.customer_id = this.payload.customerId
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    this.$store.dispatch('CustomerReports/saveReport', JSON.parse(JSON.stringify(this.form)))
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
        deleteReport(reportId) {
            this.$removeConfirm('CustomerReports/deleteReport', {
                id: reportId,
                customerId: this.form.customer_id
            }, () => {
                this.$root.$children[0].closeModal(this.$children[0].$refs['sidebar'])
                this.callback()
            })
        },
    }
}
</script>